<template>
    <div>
         <heatmap-chart />
    </div>
</template>

<script>
import HeatmapChart from './_blocks/Heatmap.vue'

export default {
    name: 'ProfileView',
    data: () => {
		return {
		}
	},
    methods: {
		
    },
    components: {
        HeatmapChart
    }
}
</script>

<style>

</style>