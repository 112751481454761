<template>
    <div class="row" v-if="stats && stats.length && heatmap.series.length">
        <div class="col-md-12 col-lg-12 col-xl-6">
            <vue-apex-charts height="400" :options="heatmap.options" :series="heatmap.series"></vue-apex-charts>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-6">
            <h5>{{ legend.date }}</h5>
            <p class="mb-1">
                <i class="mdi mdi-format-list-bulleted-type text-muted mr-1"></i> 
                Проектов создано <b>{{ legend.stats.projects_created || 0 }}</b> выполнено <b>{{ legend.stats.projects_finished || 0 }}</b>
            </p>
            <p class="mb-1">
                <i class="mdi mdi-format-list-bulleted-type text-muted mr-1"></i> 
                Задач создано <b>{{ legend.stats.tasks_created || 0 }}</b> выполнено <b>{{ legend.stats.tasks_finished || 0 }}</b>
            </p>
            <p class="mb-1">
                <i class="mdi mdi-format-list-bulleted-type text-muted mr-1"></i> 
                Подзадач создано <b>{{ legend.stats.subtasks_created || 0 }}</b> выполнено <b>{{ legend.stats.subtasks_finished || 0 }}</b>
            </p>
            <p class="mb-1">
                <i class="mdi mdi-format-list-bulleted-type text-muted mr-1"></i> 
                Таймеров запущено <b>{{ legend.stats.timers_fired || 0 }}</b> на <b>{{ timePassedFormat(legend.stats.time_spent || 0, { hours: 1, minutes: 1, seconds: 1 }) }}</b>
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

import log from '@/plugins/log'
import dayjs from '@/plugins/day'

export default {
    name: 'HeatmapChart',
    data: () => {
		return {
            stats: null,        
            heatmap: {
                options: {},
                series: []
            },
            legend: {
                date: null,
                stats: null
            }
		}
    },
    computed: {
        ...mapGetters({
			timePassedFormat: 'timer/timePassedFormat'
		}),
        id() {
            return +this.$route.params.id
        },
        dateTo() {
            return dayjs().endOf('week').format('YYYY-MM-DD')
        },
        dateFrom() {
            return dayjs(this.dateTo).add(-7 * 4, 'days').startOf('week').format('YYYY-MM-DD')
        },
        generateSeries() {
            let ts = this.dateFrom
            let series = {}

            function doGen(ts) {
                let dInstance = dayjs(ts, "YYYY-MM-DD"), dayNum = dInstance.weekday(), weekNum = dInstance.week()

                if(!series[dayNum]) series[dayNum] = {}
                if(!series[dayNum][weekNum]) series[dayNum][weekNum] = ts

                return dInstance.add(1, 'day').format('YYYY-MM-DD')
            }

            do {
                ts = doGen(ts)
            } while(ts != this.dateTo)
            ts = doGen(ts)

            return series
        },
    },
    async beforeMount() {
        await this.data()
	},
    methods: {
		async data() {
			var [err, response] = await this.$orm.query({
				stats_matrix2: {
                    where: {
                        cdate: {
                            _gt: this.dateFrom
                        },
                        user_id: {
                            _eq: this.id
                        }
                    }
                }
            })
            if(err) {
                log.error('methods.data', '$orm.queryAndSubscribe', err)
                return $.NotificationApp.send("Error", "Can't send request", "top-right", "", "error")
            }
            log.debug('methods.data', '$orm.queryAndSubscribe', response)
            this.stats = response
            this.drawChart()
        },
        scoreAt(date) {
            let stats = this.stats.find(el => el.cdate == date)
            if(!stats) return 0

            let time_spent = (stats.time_spent || 0) / 60 / 60;

            return  parseInt( (stats.projects_created || 0) + (stats.projects_finished || 0) * 2 +
                (stats.tasks_created || 0) + (stats.tasks_finished || 0) * 2 +
                (stats.subtasks_created || 0) + (stats.subtasks_finished || 0) * 2 +
                time_spent * 2
            )
        },
        drawChart() {
            this.heatmap.options = {
                chart: {
                    type: 'heatmap',
                    toolbar: {
                        show: false,
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            let serieNum = Object.keys( config.selectedDataPoints )[0]
                            let date = this.heatmap.series[serieNum].data[ config.selectedDataPoints[serieNum] ].date
                            this.drawLegend( date )
                        }
                    }
                },
                colors: ["#536de6"],
                dataLabels: {
                    enabled: false
                },
                title: {
                    text: 'Активность в трекере'
                },
                subtitle: {
                    text: '1 балл за создание 1 балл, 2 балла за завершение и каждый час работы'
                },
                xaxis: {
                    labels: {
                        rotate: -45,
                        rotateAlways: true
                    }
                }
            }

            let series = []
            let dayNumNames = [
                'Понедельник',
                'Вторник',
                'Среда',
                'Четверг',
                'Пятница',
                'Суббота',
                'Воскресенье'
            ]
             
            Object.keys(this.generateSeries).forEach(dayNum => {
                let dates = this.generateSeries[dayNum]

                let serie = {
                    name: dayNumNames[dayNum],
                    data: []
                }
                Object.keys(dates).forEach(weekNum => {
                    let date = dates[weekNum]
                    let week = dayjs().week(weekNum)
                    let weekDate = `${week.startOf('week').format('DD.MM')} - ${week.endOf('week').format('DD.MM')}`

                    serie.data.push({
                        x: weekDate,
                        y: this.scoreAt(date),
                        date: date
                    })
                })
                series.unshift( serie )
            })

            this.heatmap.series = series
            this.drawLegend()
        },
        drawLegend(date) {
            this.legend.date = date ? date : dayjs().format("YYYY-MM-DD")
            this.legend.stats = this.stats.find(el => el.cdate == this.legend.date) || {}
        }
    },
    components: {
        VueApexCharts
    }
}
</script>

<style>

</style>